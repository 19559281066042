import React from 'react';
import FingoTable from '@fingo/lib/components/tables/FingoTable';
import { PaperHeader } from '@fingo/lib/components/headers';
import FingoMainView from '@fingo/lib/components/layout/FingoMainView';
import { useMasterEntityPreColumns } from '@fingo/lib/constants';
import { GET_CREDIT_LINES } from '@fingo/lib/graphql/customers';
import { Typography } from '@mui/material';
import useGetCountryFromUrl from '@fingo/lib/hooks/useGetCountryFromUrl';
import { HEADER_TITLE } from '../../constants/credit-line-manager';
import MultiCreditLines from './MultiCreditLines';
import CreditLineActions from './CreditLineActions';

const CreditLines = () => {
  const country = useGetCountryFromUrl();

  return (
    <>
      <FingoMainView
        id="credit-line-manager"
        query={GET_CREDIT_LINES}
        queryCustomVariables={
          {
            countryId: country?.id,
          }
        }
        slots={{
          header: PaperHeader,
          table: FingoTable,
          actions: CreditLineActions,
        }}
        slotProps={{
          header: {
            viewTitle: HEADER_TITLE,
            finder: {
              searchPlaceHolder: 'Buscar empresa',
            },
          },
          table: {
            columns: useMasterEntityPreColumns(),
            includeHeaders: ['name'],
            rowsPerPageOptions: [10, 25, 50, 75, 100],
            checkboxSelection: false,
            isRowSelectable: () => true,
            disableSelectionOnClick: true,
            collapsible: true,
            isRowCollapsible: () => true,
            collapseComponent: ({ row }) => (
              <MultiCreditLines masterEntity={row} />
            ),
            noRowsMessage: () => (
              <Typography variant="body2">No hay líneas de crédito</Typography>
            ),
            initialOrderBy: 'name',
            initialState: {
              expandedRowIds: (rows) => rows.map((row) => row.id),
            },
          },
        }}
      />
    </>
  );
};

export default CreditLines;
