import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { FingoDialog } from '@fingo/lib/components/dialogs';
import { Grid, TextField } from '@mui/material';
import { useTextFieldInput } from '@fingo/lib/hooks';
import { LoadingButton } from '@mui/lab';
import useResolveCreditLineEvaluationRequest from '../../../../hooks/useResolveCreditLineEvaluationRequest';

const RejectionDialog = ({ open, onClose, evaluationId }) => {
  const [comment, setComment, clearComment] = useTextFieldInput('');
  const handleClose = useCallback(() => {
    clearComment();
    onClose();
  }, []);
  const [resolveCreditLineEvaluationRequest, { loading }] = useResolveCreditLineEvaluationRequest(
    {
      id: evaluationId,
      status: 'Rejected',
      comment,
      onCompleted: handleClose,
    },
  );

  return (
    <FingoDialog
      open={open}
      handleClose={handleClose}
      title="Rechazo de línea de crédito"
      subtitle="Ingresa el comentario del rechazo"
      dialogActionButton={(
        <LoadingButton
          disabled={!comment}
          onClick={resolveCreditLineEvaluationRequest}
          loading={loading}
        >
          Confirmar
        </LoadingButton>
      )}
    >
      <Grid container direction="row" mt={2}>
        <TextField
          required
          id="comment"
          label="Comentario de rechazo"
          variant="standard"
          value={comment}
          onChange={setComment}
          fullWidth
          multiline
          maxRows={4}
        />
      </Grid>
    </FingoDialog>
  );
};

RejectionDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  evaluationId: PropTypes.string.isRequired,
};

export default RejectionDialog;
