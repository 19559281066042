import React from 'react';
import PropTypes from 'prop-types';
import TimelineIcon from '@mui/icons-material/Timeline';
import DrawerHeader from '@fingo/lib/components/drawer/DrawerHeader';
import { ActionDrawerCell } from '@fingo/lib/components/cells';
import CreditLinePastEvaluationComponent from './CreditLinePastEvaluationsContent';

const CreditLinePastEvaluation = ({ masterEntityId }) => (
  <ActionDrawerCell
    title="Evaluaciones pasadas"
    actions={[]}
    headerComponent={DrawerHeader}
    contentComponent={CreditLinePastEvaluationComponent}
    icon={TimelineIcon}
    width="70%"
    masterEntityId={masterEntityId}
  />
);

CreditLinePastEvaluation.propTypes = {
  masterEntityId: PropTypes.string.isRequired,
};

export default CreditLinePastEvaluation;
