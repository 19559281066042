import React from 'react';
import PropTypes from 'prop-types';
import { Grid, IconButton, Tooltip } from '@mui/material';
import Cancel from '@mui/icons-material/Cancel';
import CheckCircle from '@mui/icons-material/CheckCircle';
import { useBooleanState } from '@fingo/lib/hooks';
import ReEvaluationDialog from './ReEvaluationDialog';
import RejectionDialog from './RejectionDialog';

const CreditLineEvaluationResolutionCell = ({ evaluationId, creditLineLimit }) => {
  const [openReEvaluationDialog, setOpenReEvaluationDialog] = useBooleanState(false);
  const [openRejectionDialog, setOpenRejectionDialog] = useBooleanState(false);
  return (
    <>
      <Grid container>
        <Tooltip>
          <IconButton onClick={setOpenReEvaluationDialog} color="success">
            <CheckCircle />
          </IconButton>
        </Tooltip>
        <IconButton onClick={setOpenRejectionDialog} color="error">
          <Cancel />
        </IconButton>
      </Grid>
      {openReEvaluationDialog && (
        <ReEvaluationDialog
          open={openReEvaluationDialog}
          onClose={setOpenReEvaluationDialog}
          evaluationId={evaluationId}
          creditLineLimit={creditLineLimit}
        />
      )}
      {openRejectionDialog && (
        <RejectionDialog
          open={openRejectionDialog}
          onClose={setOpenRejectionDialog}
          evaluationId={evaluationId}
        />
      )}
    </>
  );
};

CreditLineEvaluationResolutionCell.propTypes = {
  evaluationId: PropTypes.string.isRequired,
  creditLineLimit: PropTypes.number,
};

CreditLineEvaluationResolutionCell.defaultProps = {
  creditLineLimit: 0,
};

export default CreditLineEvaluationResolutionCell;
