import { Box, List, ListItem, ListItemSecondaryAction, ListItemText, Stack, Typography } from '@mui/material';
import React, { useMemo } from 'react';
import { MoneyType } from '@fingo/lib/propTypes';
import PropTypes from 'prop-types';
import { CreditLineEvaluationVariablesType } from '@fingo/lib/propTypes/CreditLineEvaluationVariablesType';

const boolean = (variable) => (variable ? 'Sí' : 'No');

const money = (variable) => variable.chileFormat;

const number = (variable) => variable;

export const INDICATORS = [
  { variableName: 'legalRepresentativeDicom', formatValue: boolean, name: 'Representante en dicom' },
  { variableName: 'creditorsQuantity', formatValue: number, name: 'Cantidad de acreedores' },
  { variableName: 'internalLeverage', formatValue: number, name: 'Leverage interno' },
  { variableName: 'debtMultiplier', formatValue: number, name: 'Multiplicador de deuda' },
  { variableName: 'customersConcentration', formatValue: number, name: 'Concentración de clientes' },
  { variableName: 'dicomRate', formatValue: number, name: 'Tasa de DICOM' },
  { variableName: 'estimatedFlow', formatValue: money, name: 'Flujo estimado' },
  { variableName: 'bankCreditline', formatValue: money, name: 'Línea con el banco' },
  { variableName: 'flowCreditline', formatValue: money, name: 'Línea de flujo' },
  { variableName: 'profitCreditline', formatValue: money, name: 'Línea de ganancias' },
];

const CreditLineSuggestionInnerContent = ({ value, row }) => {
  const creditLineEvaluationRequest = row.creditLineEvaluationRequests;
  const creditLineIndicators = creditLineEvaluationRequest?.creditlineevaluationvariablesSet[0]
    ?.creditlineevaluationindicatorsSet[0];
  const resolution = useMemo(() => {
    if (value.amount <= 0) {
      return (
        <Typography textAlign="center">
          Recomendamos
          <Typography color="primary" component="span">
            {' '}RECHAZAR{' '}
          </Typography>
          por los siguientes motivos
        </Typography>
      );
    }
    return (
      <Typography textAlign="center">
        Recomendamos
        <Typography component="span" color="success.main">
          {' '}APROBAR{' '}
        </Typography>
        por una línea de
        <Typography component="span" color="success.main">
          {' '}{value.chileFormat}{' '}
        </Typography>
      </Typography>
    );
  });
  return (
    <Box>
      <Box>
        {resolution}
      </Box>
      <Stack mt={4} spacing={2}>
        {creditLineEvaluationRequest.creditlinesuggestionSet[0]?.errorLog.map((log) => (
          <Typography key={log} width="100%" textAlign="center">
            {log}
          </Typography>
        ))}
      </Stack>
      <Box mt={4} width="100%">
        <Typography textAlign="center">
          Los indicadores considerados fueron:
        </Typography>
      </Box>
      <List dense sx={{ listStyleType: 'disc', maxWidth: 450, ml: 'auto', mr: 'auto' }}>
        {INDICATORS.map(({ variableName, formatValue, name }) => (
          <ListItem
            key={variableName}
            sx={{
              display: 'list-item',
            }}
            secondaryAction={(
              <ListItemSecondaryAction>
                <Typography variant="h6">
                  {formatValue(creditLineIndicators[variableName])}
                </Typography>
              </ListItemSecondaryAction>
            )}
          >
            <ListItemText
              primaryTypographyProps={{
                variant: 'body1',
              }}
            >
              {name}:
            </ListItemText>
          </ListItem>
        ))}
      </List>
    </Box>
  );
};

CreditLineSuggestionInnerContent.propTypes = {
  value: MoneyType,
  row: PropTypes.shape({
    id: PropTypes.string.isRequired,
    creditLineEvaluationRequests: PropTypes.shape({
      id: PropTypes.string.isRequired,
      creditlinesuggestionSet: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.string.isRequired,
          errorLog: PropTypes.arrayOf(PropTypes.string).isRequired,
        }),
      ).isRequired,
      creditlineevaluationvariablesSet: PropTypes.arrayOf(
        CreditLineEvaluationVariablesType.isRequired,
      ),
    }),
  }).isRequired,
};
CreditLineSuggestionInnerContent.defaultProps = {
  value: null,
};
export default CreditLineSuggestionInnerContent;
