import { useQuery } from '@apollo/client';
import { FingoDataGrid } from '@fingo/lib/components/dataGrids';
import FingoTabs from '@fingo/lib/components/tabs/FingoTabs';
import { Box } from '@mui/material';
import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import { CREDIT_LINE_EVALUATION_REQUESTS_BY_MASTERENTITY } from '@fingo/lib/graphql';
import { COLUMNS } from '../../constants/credit-line-evaluation-requests';

const HistoryList = ({ masterEntityId }) => {
  const numberOfPastEvaluations = 10;
  const { data, loading } = useQuery(CREDIT_LINE_EVALUATION_REQUESTS_BY_MASTERENTITY, {
    variables: {
      masterEntityId,
    },
  });
  const rows = useMemo(() => {
    if (!data) return [];
    const {
      id, name, displayNationalIdentifier, creditLineEvaluationRequestsAll,
    } = data.getMasterEntity;
    return creditLineEvaluationRequestsAll.map((req) => (
      { id, name, displayNationalIdentifier, creditLineEvaluationRequests: req }
    ));
  }, [data]);
  const includeHeaders = [
    'masterEntity_Name',
    'requestDate',
    'status',
    'approvedAmount',
    'comment',
  ];
  return (
    <Box height="100%" width="100%">
      <FingoDataGrid
        rows={rows}
        includeHeaders={includeHeaders}
        columns={COLUMNS}
        loadingWithSkeleton={loading}
        serverFilters={false}
        rowCount={numberOfPastEvaluations}
        page={0}
        pageSize={numberOfPastEvaluations}
        hideFooter
      />
    </Box>
  );
};

HistoryList.propTypes = {
  masterEntityId: PropTypes.string.isRequired,
};

const CreditLinePastEvaluationComponent = ({ masterEntityId }) => (
  <FingoTabs
    tabInfo={[
      {
        tabName: 'Cliente',
        component: HistoryList,
        componentProps: { masterEntityId },
      },
    ]}
  />
);

CreditLinePastEvaluationComponent.propTypes = {
  masterEntityId: PropTypes.string.isRequired,
};

export default CreditLinePastEvaluationComponent;
