/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import FingoTable from '@fingo/lib/components/tables/FingoTable';
import { IconButton, Typography, Box } from '@mui/material';
import { useBooleanState } from '@fingo/lib/hooks';
import { useCreditLinePreColumns } from '@fingo/lib/constants';
import Edit from '@mui/icons-material/Edit';
import EditCreditLine from './EditCreditLine';

const MultiCreditLines = ({ masterEntity }) => {
  const rowsWithCreditLineInfo = masterEntity.creditlines.map((detail) => ({
    ...detail,
  }));
  const [openEdit, toggleEdit] = useBooleanState(false);
  const [selectedCreditLine, setSelectedCreditLine] = useState(null);
  const handleOnEdit = (creditLine) => {
    setSelectedCreditLine(creditLine);
    toggleEdit();
  };

  const customColumns = [
    {
      field: 'edit',
      sortable: false,
      filterable: false,
      renderCell: (params) => (
        <Box display="flex" justifyContent="center" alignItems="center">
          <IconButton
            size="small"
            align="center"
            onClick={() => handleOnEdit(params.row)}
          >
            <Edit />
          </IconButton>
        </Box>
      ),
      flex: 1,
    },
  ];

  return (
    <>
      <FingoTable
        rows={rowsWithCreditLineInfo}
        columns={useCreditLinePreColumns(customColumns)}
        includeHeaders={[
          'lastUpdate',
          'currency',
          'creditUsed',
          'edit',
        ]}
        noRowsMessage={
          () => <Typography>La empresa no tiene líneas de crédito</Typography>
        }
        pagination={false}
        loading={false}
        customPaperBackground="transparent"
        screenHeight="auto"
      />
      {openEdit && (
        <EditCreditLine
          masterEntity={{
            id: masterEntity.id,
            name: masterEntity.name,
          }}
          creditLine={selectedCreditLine}
          open={openEdit}
          handleClose={toggleEdit}
        />
      )}
    </>
  );
};

export default MultiCreditLines;
