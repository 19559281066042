import React, { useCallback, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { FingoDialog } from '@fingo/lib/components/dialogs';
import { Grid, TextField } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import { useTextFieldInput } from '@fingo/lib/hooks';
import { LoadingButton } from '@mui/lab';
import useResolveCreditLineEvaluationRequest from '../../../../hooks/useResolveCreditLineEvaluationRequest';

const ReEvaluationDialog = ({ open, onClose, evaluationId, creditLineLimit }) => {
  const [reEvaluationDate, setReEvaluationDate] = useState(null);
  const [approvedAmount, _setApprovedAmount] = useState(0);
  const [comment, setComment, clearComment] = useTextFieldInput();
  const setApprovedAmount = useCallback((event) => {
    const number = event.target.value || 0;
    _setApprovedAmount(parseInt(number, 10));
  }, []);

  const handleClose = useCallback(() => {
    _setApprovedAmount(0);
    clearComment();
    setReEvaluationDate(null);
    onClose();
  }, []);
  const status = useMemo(() => {
    if (approvedAmount < creditLineLimit) return 'Decreased';
    if (approvedAmount === creditLineLimit) return 'Kept';
    if (approvedAmount > creditLineLimit) return 'Increased';
    return '';
  }, [approvedAmount, creditLineLimit]);
  const [resolveCreditLineEvaluationRequest, { loading }] = useResolveCreditLineEvaluationRequest(
    {
      id: evaluationId,
      status,
      comment,
      approvedAmount,
      reEvaluationDate,
      onCompleted: handleClose,
    },
  );

  return (
    <FingoDialog
      open={open}
      handleClose={handleClose}
      title="Aprobación de línea de crédito"
      subtitle="Ingresa el monto, comentario de aprobación y una fecha de próxima revisión (si se requiere)"
      dialogActionButton={(
        <LoadingButton
          disabled={!approvedAmount || !comment}
          onClick={resolveCreditLineEvaluationRequest}
          loading={loading}
        >
          Aceptar
        </LoadingButton>
      )}
    >
      <Grid container direction="row" justifyContent="space-evenly" alignItems="center" mt={3}>
        <TextField
          required
          id="approvedAmount"
          label="Monto aprobado"
          type="number"
          value={approvedAmount}
          onChange={setApprovedAmount}
          InputLabelProps={{ shrink: true }}
          variant="standard"
        />
        <DatePicker
          renderInput={(params) => (
            <TextField
              {...params}
              variant="standard"
              inputProps={{ ...params.inputProps, 'data-hj-allow': '' }}
            />
          )}
          id="reEvaluationDate"
          label="Fecha próxima revisión"
          value={reEvaluationDate}
          onChange={setReEvaluationDate}
        />
      </Grid>
      <Grid container direction="row" mt={2}>
        <TextField
          required
          id="comment"
          label="Comentario de aprobación"
          variant="standard"
          value={comment}
          onChange={setComment}
          fullWidth
          multiline
          maxRows={3}
        />
      </Grid>
    </FingoDialog>
  );
};

ReEvaluationDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  evaluationId: PropTypes.string.isRequired,
  creditLineLimit: PropTypes.number.isRequired,
};

export default ReEvaluationDialog;
